import React from 'react'
import { Segment, Container, Header, Card, Image } from 'semantic-ui-react'
import Layout from '../components/layout'
import PageHeader from '../components/PageHeader'

import apn_logo from '../images/logo-consulting-partner-aws-mangrove.svg'

import './consulting.css'

const ConsultingPrimary = () => (
  <div>
    <Segment vertical inverted className="SubHeader">
      <div className="bg">
        <Segment vertical>
          <Container text>
            <p>
              As an Advanced Technology Partner, Mangrove can help you build
              your future, faster with Amazon Web Services.
            </p>
            <p>
              Our expertise is top-notch — but it all starts with your business
              goals.
            </p>
            <a
              href="https://aws.amazon.com/fr/partners/find/partnerdetails/?n=Mangrove%20Consulting&id=0010h00001aCXe0AAG"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Image src={apn_logo} size="small" centered alt="APN" />
            </a>
          </Container>
        </Segment>
      </div>
    </Segment>
    <Segment as="section" basic>
      <Container>
        <Card.Group centered stackable itemsPerRow={2}>
          <Card>
            <Card.Content>
              <Card.Header>Machine Learning</Card.Header>
              <Card.Description>
                Want solve data challenges, enable machine learning or deploy
                data science workflows into your applications? Thanks to his
                deep expertise in Machine Learning on the AWS platform our team
                can help you deliver seamless solutions in the AWS Cloud
                environment.
              </Card.Description>
            </Card.Content>
          </Card>
          <Card>
            <Card.Content>
              <Card.Header>Migrations</Card.Header>
              <Card.Description>
                Our team will evaluate your existing infrastructure and design
                your roadmap to the cloud. We'll help you migrate your key
                business applications to AWS while ensuring high performance and
                scalability. And we’ll give you a detailed understanding of the
                ROI before and after the migration.
              </Card.Description>
            </Card.Content>
          </Card>
          <Card>
            <Card.Content>
              <Card.Header>Infrastructure</Card.Header>
              <Card.Description>
                We’ll create your AWS environment based on your security,
                connectivity, storage, and computing power requirements.
              </Card.Description>
            </Card.Content>
          </Card>
          <Card>
            <Card.Content>
              <Card.Header>DevOps &amp; Automation</Card.Header>
              <Card.Description>
                We’ll help your teams build and ship software better and faster,
                represent your infrastructure as code, and increase your speed
                to market.
              </Card.Description>
            </Card.Content>
          </Card>
          <Card>
            <Card.Content>
              <Card.Header>Application development services</Card.Header>
              <Card.Description>
                Need more than a lift-and-shift migration? We can re-platform
                your existing applications so they’re AWS-compatible. We also
                architect and deliver SaaS-based applications on top of AWS
                following its best practices.
              </Card.Description>
            </Card.Content>
          </Card>
        </Card.Group>
      </Container>
    </Segment>
  </div>
)

export default () => (
  <Layout>
    <div className="ConsultingPage">
      <PageHeader>
        <Header inverted as="h1">
          Build at the speed of change with AWS and Mangrove.
        </Header>
      </PageHeader>
      <ConsultingPrimary />
    </div>
  </Layout>
)
